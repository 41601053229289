import { FC, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { SuperTableStatic } from '../../../components/tables/SuperTableStatic'
import { ColumnDropdown } from '../../../components/tables/ColumnDropdown'
import Swal from 'sweetalert2'
import { Loading } from '../../../components/loaders/Loading'
import { useTranslation } from 'react-i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import React from 'react'
import { TableColumn } from 'react-data-table-component'
import { ProjectDataRow } from '../core/_models'
import { dateToString } from '../../../helpers/DateHelper'
import { releaseDevicesByProjectId } from '../core/_requests'
import clsx from 'clsx'
import PermissionProvider from '../../../PermissionProvider/PermissionProvider'
import Restricted from '../../../PermissionProvider/Restricted'
import { fetchPermission } from '../../../helpers/CommonHelper'
import { useAuth } from '../../auth'
import { Steps } from 'intro.js-react'
import { updateDeviceStatusProjectId } from '../../apps/project/core/_requests'

type Props = {
  data: Array<any>
  handleDeleteProjects: (ids: any) => void | undefined
  filterOption: {
    isArchive: boolean
  }
  setFilterOption: (option: any) => void | undefined
  isRefetching?: boolean
  handleProjectPlaceholder?: () => void
  authData?: any
  stepsEnabled?: boolean
  steps?: any
  initialStep?: any
  onExit?: any
}

const ProjectInfoTable: FC<Props> = ({
  data,
  handleDeleteProjects,
  filterOption,
  setFilterOption,
  isRefetching,
  handleProjectPlaceholder,
  authData,
  initialStep,
  onExit,
  steps,
  stepsEnabled,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [endedProjects, setEndedProjects] = useState<{ [key: number]: boolean }>({})

  const endProjects = async (selectedProjects: any, isArchiving: boolean) => {
    setIsLoading(true)
    try {
      if (!isArchiving) {
        const result = await Swal.fire({
          title: t('confirm_device_release_message'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: t('yes'),
          cancelButtonText: t('cancel'),
        })

        if (!result.isConfirmed) {
          throw new Error('User cancelled the action.')
        }
      }

      // Attempt to end all projects, throwing if any fail
      await Promise.all(
        selectedProjects.map(async (project: any) => {
          const projectNumber = project.projectNumber
          try {
            await releaseDevicesByProjectId(projectNumber) // Marvellous -- this uses project id as a name but it really needs the project number for now it's w.e.
            setEndedProjects((prev) => ({ ...prev, [projectNumber]: true }))
          } catch (error) {
            console.error(`Error ending project ${projectNumber}`, error)
            throw new Error(`Failed to end project ${projectNumber}`)
          }
        })
      )

      if (!isArchiving) {
        Swal.fire(t('success'), t('device_released'), 'success')
      }
    } catch (error) {
      console.error('Error in endProjects:', error)
      throw error // Rethrow to stop archival process
    } finally {
      setIsLoading(false)
    }
  }

  const endProjectColumn = (row: any) => {
    const ended = endedProjects[row.project_number] || row.is_Ended

    return (
      <button
        key={row._id}
        type='button'
        className={`btn btn-primary btn-sm me-1 p-3 w-100 ${ended ? 'disabled_button' : ''}`}
        disabled={ended}
        onClick={() => endProjects([{ id: row._id, projectNumber: row.project_number }], false)}
      >
        {isLoading ? 'Loading...' : t('end_project')}
      </button>
    )
  }

  const ProjectColumns: TableColumn<ProjectDataRow>[] = [
    {
      id: 'name',
      name: t('name_'),
      sortable: true,
      width: '250px',
      omit: false,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        const nameA = a.name === '-' ? '' : a.name // Handle special case for placeholder
        const nameB = b.name === '-' ? '' : b.name // Handle special case for placeholder

        return nameA?.localeCompare(nameB, undefined, { sensitivity: 'base' })
      },
      cell: (row) => {
        if (currentUser?.role == 'guest') {
          return (
            <>
              <a href={`/apps/project/measurement/${row._id}`} key={row._id}>
                {row.name}
              </a>
            </>
          )
        } else {
          return (
            <>
              <a href={`/apps/project/status/${row._id}`} key={row._id}>
                {row.name}
              </a>
            </>
          )
        }
      },
    },
    {
      id: 'lable',
      name: t('lable_'),
      width: '80px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        const nameA = a.lable === '-' ? '' : a.lable // Handle special case for placeholder
        const nameB = b.lable === '-' ? '' : b.lable // Handle special case for placeholder

        return nameA?.localeCompare(nameB, undefined, { sensitivity: 'base' })
      },
      omit: false,
      selector: (row) => (row.lable ? row.lable : '-'),
    },
    {
      id: 'location',
      name: t('location_'),
      width: '100px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        const nameA = a.location === '-' ? '' : a.location // Handle special case for placeholder
        const nameB = b.location === '-' ? '' : b.location // Handle special case for placeholder

        return nameA?.localeCompare(nameB, undefined, { sensitivity: 'base' })
      },
      omit: false,
      selector: (row) => (row.location ? row.location : '-'),
    },
    {
      id: 'project_number',
      name: t('project_number_'),
      width: '140px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => a.project_number - b.project_number,
      omit: false,
      selector: (row) => (row.project_number ? row.project_number : '-'),
    },
    {
      id: 'id',
      name: t('device_number'),
      width: '100px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => a.device_number - b.device_number,
      omit: false,
      selector: (row) => (row.device_number ? row.device_number : '-'),
    },
    {
      id: 'end_date',
      name: t('end_date_'),
      width: '110px',
      sortable: true,
      omit: false,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        if (!a.end_date) return -1
        if (a.end_date === 'No end date specified') return -1
        if (!b.end_date) return 1
        if (b.end_date === 'No end date specified') return 1
        if (a.end_date.includes('Today') && b.end_date.includes('Today')) {
          const aTime = parseInt(a.end_date.split(' ')[1].replace(':', ''), 10)
          const bTime = parseInt(b.end_date.split(' ')[1].replace(':', ''), 10)
          return aTime - bTime
        }

        return new Date(a.end_date).getTime() - new Date(b.end_date).getTime()
      },
      selector: (row) =>
        row.end_date
          ? `${dateToString(row.end_date, row.time_zone)}`
          : `${t('no_end_date_specified')}`,
    },
    {
      id: 'last_contact',
      name: t('last_contact_'),
      width: '130px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        if (!a.last_contact) return -1
        if (!b.last_contact) return 1
        if (a.last_contact.includes('Today') && b.last_contact.includes('Today')) {
          const aTime = parseInt(a.last_contact.split(' ')[1].replace(':', ''), 10)
          const bTime = parseInt(b.last_contact.split(' ')[1].replace(':', ''), 10)
          return aTime - bTime
        }

        return new Date(a.last_contact).getTime() - new Date(b.last_contact).getTime()
      },
      omit: false,
      selector: (row) =>
        row.last_contact ? `${dateToString(row.last_contact, row.time_zone)}` : '-',
    },
    {
      id: 'next_contact',
      name: t('next_contact_'),
      width: '130px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        if (!a.next_contact) return -1
        if (!b.next_contact) return 1
        if (a.next_contact.includes('Today') && b.next_contact.includes('Today')) {
          const aTime = parseInt(a.next_contact.split(' ')[1].replace(':', ''), 10)
          const bTime = parseInt(b.next_contact.split(' ')[1].replace(':', ''), 10)
          return aTime - bTime
        }

        return new Date(a.next_contact).getTime() - new Date(b.next_contact).getTime()
      },
      omit: false,
      selector: (row) =>
        row.next_contact ? `${dateToString(row.next_contact, row.time_zone)}` : '-',
    },
    {
      id: 'charge',
      name: t('charge_'),
      width: '120px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => a.charge - b.charge,
      omit: false,
      selector: (row) => Math.floor(row.charge),
      cell: (row) => (
        <>
          <div className='w-30px'>{`${Math.floor(row.charge)}%`}</div>
          <div
            className={clsx(
              (row.charge >= 0 && row.charge <= 30
                ? 'bg-danger  rounded-pill h-20px w-20px ms-lg-10'
                : '') ||
                (row.charge > 30 && row.charge <= 80
                  ? 'bg-warning rounded-pill h-20px w-20px ms-lg-10'
                  : '') ||
                (row.charge > 80 && row.charge <= 100
                  ? 'bg-success rounded-pill h-20px w-20px ms-lg-10'
                  : '')
            )}
          />
        </>
      ),
    },

    {
      id: 'peak_vb',
      name: t('peak_vb_'),
      width: '100px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => a.peak_vb - b.peak_vb,
      omit: false,
      selector: (row) => `${(Math.round(row.peak_vb * 100) / 100).toFixed(2)} mm/s`,
    },
    {
      id: 'fmg',
      name: t('fmg_'),
      width: '80px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => a.peak_fvb - b.peak_fvb,
      omit: false,
      selector: (row) => `${(Math.round(row.peak_fvb * 100) / 100).toFixed(2)} Hz`,
    },
    {
      id: 'timestamp',
      name: t('timestamp_'),
      width: '120px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => {
        if (!a.timestamp) return -1
        if (!b.timestamp) return 1
        if (a.timestamp.includes('Today') && b.timestamp.includes('Today')) {
          const aTime = parseInt(a.timestamp.split(' ')[1].replace(':', ''), 10)
          const bTime = parseInt(b.timestamp.split(' ')[1].replace(':', ''), 10)
          return aTime - bTime
        }

        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      },
      omit: false,
      selector: (row) => `${dateToString(row.timestamp, row.time_zone)}`,
    },
    {
      id: 'Dataupload',
      name: t('data_upload_'),
      width: '120px',
      sortable: true,
      sortFunction: (a: ProjectDataRow, b: ProjectDataRow) => a.data_upload - b.data_upload,
      omit: false,
      selector: (row) => `${row.data_upload} MB`,
    },
    {
      id: 'action',
      name: t('action_'),
      sortable: false,
      sortFunction: (a: any, b: any) => a.is_Ended - b.is_Ended,
      width: '120px',
      button: true,
      omit: false,
      cell: (row) => {
        if (currentUser?.role == 'guest' || currentUser?.role == 'user') {
          return ''
        } else {
          return endProjectColumn(row)
        }
      },
    },
  ]

  const filteredColumns = filterOption.isArchive
    ? ProjectColumns.filter((column) => column.id !== 'action')
    : ProjectColumns

  const [columnData, setcolumnData] = useState(ProjectColumns)
  const [selectedProjects, setSelectedProjects] = useState<Array<{ id: any; projectNumber: any }>>(
    []
  )

  const [id, setId] = useState<string>('collapseTarget2')

  let isLoadingData = isRefetching || false
  const handleSelect = (id: any) => {
    columnData[id].omit = !columnData[id].omit
    setcolumnData([...columnData])
  }

  const handleProjectSelect = (state: any) => {
    const projects = state.selectedRows.map((project: any) => ({
      id: project._id,
      projectNumber: project.project_number,
    }))
    setSelectedProjects(projects)
  }

  const handeChange = (e: any) => {
    filterOption.isArchive = e.target.checked
    setFilterOption(filterOption)
  }

  // Ends projects and sets isArchive flag to true
  const archiveProjects = async () => {
    if (selectedProjects.length === 0) {
      Swal.fire({
        title: t('archive_project'),
        customClass: { popup: 'px-6 py-6' },
        text: t('select_project_for_archive'),
        confirmButtonText: t('ok'),
      })
      return
    }

    try {
      const result = await Swal.fire({
        title: t('archive_project'),
        customClass: { popup: 'px-6 py-6' },
        text: t('are_you_sure_for_project_archive'),
        confirmButtonText: t('yes'),
        cancelButtonText: t('no'),
        showCancelButton: true,
      })

      if (!result.isConfirmed) return

      isLoadingData = true

      await endProjects(selectedProjects, true)
      await handleDeleteProjects(selectedProjects.map((project) => project.id))

      Swal.fire({
        title: t('project_archived'),
        customClass: { popup: 'px-6 py-6' },
        text: t('project_archived_you_can_show'),
        confirmButtonText: t('ok'),
        icon: 'success',
      })
    } catch (error) {
      console.error('Error archiving projects:', error)
      Swal.fire({
        title: t('error'),
        text: t('failed_to_end_projects'),
        icon: 'error',
        confirmButtonText: t('ok'),
      })
    } finally {
      isLoadingData = false
    }
  }

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {t('project_table_2')}
    </Tooltip>
  )
  return (
    <>
      <div className='project project_overview'>
        <div className='flex-grow-1 mt-10'>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-0'>
            <div className='d-flex flex-column mb-0'>
              <div className='d-flex align-items-center mb-0'>
                <span className='text-black-600 text-hover-primary fs-1 fw-bolder me-1 mb-0'>
                  {t('project_overview')}
                </span>
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button className='btn p-0 rounded-circle' type='button'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='svg-icon-1 fw-bold text-hover-primary'
                    />
                  </button>
                </OverlayTrigger>
              </div>
              <div className='d-flex align-items-right mb-0'></div>
            </div>
            <div className='d-flex my-0 mb-4'>
              <PermissionProvider fetchPermission={fetchPermission(currentUser)}>
                <Restricted to='project.create'>
                  {authData?.placeholder_screen?.dashboard === 0 && (
                    <Steps
                      enabled={stepsEnabled}
                      steps={steps}
                      initialStep={initialStep}
                      onExit={onExit}
                    />
                  )}
                  <div className='form-check form-switch form-check-custom form-check-solid switch-custom me-2'>
                    <label className='form-check-label me-2'>{t('archived_data')}</label>
                    <input
                      className='form-check-input archive'
                      type='checkbox'
                      disabled={isLoadingData}
                      onChange={(e) => handeChange(e)}
                      id='flexSwitchDefault'
                    />
                  </div>
                  <a
                    href='/apps/project/create'
                    className='btn btn-sm btn-secondary mx-2 add_project'
                  >
                    {t('add_project')}
                  </a>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary project_archive'
                    onClick={archiveProjects}
                    disabled={filterOption.isArchive}
                  >
                    {t('archive_project')}
                  </button>
                </Restricted>
              </PermissionProvider>
              <div className='dropdown position-relative'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm btn-icon ms-2'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='bottom-end'
                  data-bs-target='#collapseTarget2'
                  data-bs-toggle='collapse'
                  data-bs-menu-trigger='click'
                  data-bs-menu-placement='bottom-end'
                  data-bs-menu-flip='bottom-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>

                <ColumnDropdown columns={columnData} selectColumn={handleSelect} id={id} />
              </div>
            </div>
          </div>
        </div>
        <div className='card table-responsive' style={{ minHeight: '200px' }}>
          {isLoadingData && <Loading></Loading>}
          {!isLoadingData && (
            <div>
              <SuperTableStatic
                data={data}
                pagination={true}
                columns={filteredColumns}
                height={''}
                selectableRows={true}
                handleSelect={handleProjectSelect}
                showCheckbox={true}
                showConfigStatus={false}
              ></SuperTableStatic>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { ProjectInfoTable }

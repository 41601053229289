import axios, { AxiosResponse } from 'axios'
import { ProjectDataRow } from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Moment } from 'moment'

const API_URL = process.env.REACT_APP_REST_API_URL
const PROJECT_URL = `${API_URL}/project`
const config = {
  headers: {
    'Content-Type': 'multipart/form-data boundary=----WebKitFormBoundaryufBehVABJlOeMAGe',
  },
}

const getDeviceDataRows = (id: any): Promise<any> => {
  return axios.get(`${PROJECT_URL}/${id}`).then((d: AxiosResponse<any>) => d.data)
}
const getProjectStatusData = (id: any): Promise<any> => {
  return axios
    .post(`${PROJECT_URL}/chart/status/data`, { id: id })
    .then((d: AxiosResponse<any>) => d.data)
}
const getDeviceList = (id: any): Promise<any> => {
  return axios.get(`${PROJECT_URL}/devices/${id}`).then((d: AxiosResponse<any>) => d.data)
}
const getAllDeviceDataRows = (): Promise<any> => {
  return axios.get(`${API_URL}/device/`).then((d: AxiosResponse<any>) => d.data)
}

const getSelectedDeviceFrequency = (id: any): Promise<any> => {
  return axios.get(`${API_URL}/device/frequancy/${id}`).then((d: AxiosResponse<any>) => d.data)
}

const getAvailableDeviceDataRows = (): Promise<any> => {
  return axios.get(`${API_URL}/device?projectadd=1`).then((d: AxiosResponse<any>) => d.data)
}

const getAvailableDeviceData = (id: any): Promise<any> => {
  return axios
    .get(`${API_URL}/project/edit/devicedata/${id}`)
    .then((d: AxiosResponse<any>) => d.data)
}
const getAvailablUsersData = (query = ''): Promise<any> => {
  return axios.get(`${API_URL}/users/userdetail${query}`).then((d: AxiosResponse<any>) => d.data)
}

const setCurrentUserDetail = (user: any): Promise<any> => {
  return axios
    .post(`${API_URL}/auth/update`, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const deleteSelectedProjects = (prjectIds: Array<ID>): Promise<void> => {
  const requests = prjectIds.map((id) => axios.delete(`${PROJECT_URL}/${id}`))
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return axios.all(requests).then(() => {})
}

const getVelocityChartData = (filterData: any): Promise<any> => {
  return axios
    .post(`${API_URL}/chart/velocitychart`, filterData)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getPeakDevicesChartData = (): Promise<any> => {
  // let request = {
  //   project_id: '1335',
  //   start_date: '2022-11-24',
  //   end_date: '2022-11-24',
  // }
  return axios
    .post(`${API_URL}/chart/peakchart`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getAccelerationChartData = (filterData: any): Promise<any> => {
  return axios
    .post(`${API_URL}/chart/accelerationchart`, filterData)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getFrequencyTimeChartData = (filterData: any): Promise<any> => {
  return axios
    .post(`${API_URL}/chart/frequancychart`, filterData)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getVelocityFrequencyChartData = (filterData: any): Promise<any> => {
  return axios
    .post(`${API_URL}/chart/velocityfrequncychart`, filterData)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getKbDataChartData = (): Promise<any> => {
  const request = {
    project_id: '1335',
    start_date: '2022-11-24',
    end_date: '2022-11-24',
  }
  return axios
    .post(`${API_URL}/chart/kbdatachart`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const getPeakVbVsTime = (project_id: any, startDate: Moment, endDate: Moment): Promise<any> => {
  const request = {
    id: project_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/velocitychart`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const getVelocityVsFrequency = (
  project_id: any,
  startDate: Moment,
  endDate: Moment
): Promise<any> => {
  const request = {
    id: project_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/v/frequancy`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const getPeakVbVsTimeChart = (
  project_id: any,
  startDate: Moment,
  endDate: Moment
): Promise<any> => {
  const request = {
    id: project_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/peak/vbchart`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getPeakVVsTimeChart = (project_id: any, startDate: Moment, endDate: Moment): Promise<any> => {
  const request = {
    id: project_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/peak/vchart`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getPeakVelocityVsFrequency = (
  project_id: any,
  startDate: Moment,
  endDate: Moment
): Promise<any> => {
  const request = {
    id: project_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/vb/frequancy`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getPeakVelocityVsFrequencyDeviceWise = (
  project_id: any,
  device_id: any,
  startDate: Moment,
  endDate: Moment
): Promise<any> => {
  const request = {
    id: project_id,
    device_id: device_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/device/frequancy`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const getPeakVelocityVsTimeDeviceWise = (
  project_id: any,
  device_id: any,
  startDate: Moment,
  endDate: Moment
): Promise<any> => {
  const request = {
    id: project_id,
    device_id: device_id,
    start_date: startDate,
    end_date: endDate,
  }
  return axios
    .post(`${PROJECT_URL}/chart/device`, request)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const getProjectLogs = (project_id: any): Promise<any> => {
  return axios.get(`${API_URL}/logs/${project_id}`).then((d: AxiosResponse<any>) => d.data)
}

const getProjectUsersList = (project_id: any): Promise<any> => {
  return axios.get(`${PROJECT_URL}/users/${project_id}`).then((d: AxiosResponse<any>) => d.data)
}

const createProject = (project: any): Promise<ProjectDataRow | undefined> => {
  return axios
    .post(PROJECT_URL, project, config)
    .then((response: AxiosResponse<Response<ProjectDataRow>>) => response.data)
    .then((response: Response<ProjectDataRow>) => response.data)
}

const updateProject = (project: any, id: any): Promise<ProjectDataRow | undefined> => {
  return axios
    .post(`${PROJECT_URL}/edit/${id}`, project, config)
    .then((response: AxiosResponse<Response<ProjectDataRow>>) => response.data)
    .then((response: Response<ProjectDataRow>) => response.data)
}
const addUserWithAssignProject = (project: any): Promise<any | undefined> => {
  return axios
    .post(`${PROJECT_URL}/create-user-and-assign-to-project`, project)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const assignUserToProject = (project: any): Promise<any | undefined> => {
  return axios
    .post(`${PROJECT_URL}/user-assign-to-project`, project)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getDevicesIds = (): Promise<any> => {
  return axios.get(`${API_URL}/device/filterdata`).then((d: AxiosResponse<any>) => d.data)
}

const getProjectsIds = (): Promise<any> => {
  return axios.get(`${API_URL}/project/filterdata`).then((d: AxiosResponse<any>) => d.data)
}

const updateDeviceId = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/device/updateDevice/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
}

const updateDeviceStatusProjectId = (deviceId: any, data: any): Promise<any> => {
  return axios
    .post(`${API_URL}/device/updateDeviceStatusProjectId/${deviceId}`, data)
    .then((response: AxiosResponse<any>) => response.data)
}

const getprojectDevices = (id: any): Promise<any> => {
  return axios
    .get(`${API_URL}/device/getProjectDeviceIds/${id}`)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateSources = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/project/updateSource/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}
const updateNewSources = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/project/update-newsources/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}
const updateProjectConfigClient = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/project/update-project-config/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}
const updateNewMountLocationsDescriptions = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/project/update-new-mount-location-descriptions/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}
const updateSiteDescription = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/project/update-site-description/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}
const updateNotifications = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/project/update-notifications/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data)
}
export {
  getDeviceDataRows,
  getDeviceList,
  getAllDeviceDataRows,
  updateProject,
  createProject,
  getProjectLogs,
  getAvailableDeviceDataRows,
  getAvailableDeviceData,
  getAvailablUsersData,
  getVelocityChartData,
  getFrequencyTimeChartData,
  getAccelerationChartData,
  getKbDataChartData,
  getVelocityFrequencyChartData,
  getDevicesIds,
  getProjectsIds,
  setCurrentUserDetail,
  deleteSelectedProjects,
  getSelectedDeviceFrequency,
  getPeakDevicesChartData,
  addUserWithAssignProject,
  getProjectUsersList,
  getPeakVbVsTime,
  getVelocityVsFrequency,
  getPeakVbVsTimeChart,
  getPeakVelocityVsFrequency,
  getPeakVVsTimeChart,
  assignUserToProject,
  getProjectStatusData,
  getPeakVelocityVsFrequencyDeviceWise,
  getPeakVelocityVsTimeDeviceWise,
  updateDeviceId,
  getprojectDevices,
  updateSources,
  updateNewSources,
  updateProjectConfigClient,
  updateNewMountLocationsDescriptions,
  updateSiteDescription,
  updateNotifications,
  updateDeviceStatusProjectId,
}
